import React, { useContext } from 'react'
import './Popup2.css'
import { appContext } from '../../App'
import DatumOperations from '../datum/DatumOperations'
import { popUpProp } from '../place/PlaceContainer'
import buttonYes from '../../assets/buttonYes.png'
import buttonNo from '../../assets/buttonNo.png'

let popupMessage
let buttonClose = 'invisible'
let buttonOk = 'invisible'
let originalMessage
let splittedMessage
let trigger
let popup2List = []
let popup2Element
let popup2Visibility
let popup2CellVisibility
let popup2Hely

function Popup2() {



//appContext
const [ authenticated, setAuthenticated, appTimeList, setAppTimeList, appFaligazdaList, setAppFaligazdaList, appHelyList, setAppHelyList, appDatumList, setAppDatumList, appContactList, setAppContactList, user, popup, setPopup, popup2, setPopup2, actualFg, setActualFg, actualHely, setActualHely ] = useContext(appContext)


  trigger = popup2.substring(0, 4)
  popup2List = popUpProp()

  function setPop2(caller) {


    switch(caller){
      case 'showPopup' : {
        splittedMessage = popup2.split("#", 5)
        popup2Hely = helyName(splittedMessage[4])
        popupMessage = 'Az időpont nem választható'
        buttonClose = 'visible'
        buttonOk = 'invisible'

        break
      }
      case 'close' : {
        setPopup2('hide')
        break
      }
      case 'OK' : {
        setPopup2('hide')
        break
      }
      default : {
        buttonClose = 'visible'
        buttonOk = 'visible'
      }
    }
  }

  const click = (element) => {
    element.buttonName === 'FOGLALT' ? setPopup('show#FOGLALT') :
      element.buttonName === 'nem válaszhtató' ? setPopup('show#NEMV') :
      setPopup( 'show#' + element.fg_id + '#' + element.hely_id + '#' + user.email + '#' + 'lefoglalja az időpontot?\n' + element.buttonName + '\n' + element.fgName + '\n' + element.nap + '  ' + element.ora + ' óra')

  }

  const helyName = (id) => {
    return appHelyList.find(element => element.hely_id == id)
  }

    return(
      <div>
      {trigger === 'show' ? setPop2('showPopup') :  setPop2('OK')}
      <div id = 'titlePopup2'>
        <h1>{(splittedMessage == undefined && popup2 == 'hide') ? <div>undefined</div> : <div>{splittedMessage[2]}{' - '}{popup2Hely.hely_nev}</div>}</h1>
      </div>
        <table id = "popup2Table">
          <tbody>
            <tr>
              <th>{(splittedMessage == undefined && popup2 == 'hide') ? <div>undefined</div> : <div>{splittedMessage[3]}</div>}</th>
            </tr>
            <td>
              {popup2Element = popup2List
                .map((tableElement, index) => (<tr key={index}><td id = 'popup2cell'><button
                  id = {tableElement.visibility}
                  onClick={(e) => click(tableElement, splittedMessage[3])}>
                  {helyName(tableElement.hely_id).hely_nev}</button></td></tr>))}
            </td>
          </tbody>
        </table>
        {<br></br>}{<br></br>}
        <div id = 'button1' className = {buttonClose}>
        <img id = 'button2No' src = {buttonNo} onClick = {(e) => setPopup2("close")} ></img>
        </div>
        <div id = 'button2' className = {buttonOk}>
        <img id = 'button2Yes' src = {buttonYes} onClick = {(e) => setPopup2("OK")} ></img>
        </div>
      </div>
    )
}

export default Popup2
