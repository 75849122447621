import React, { Component } from "react";
import "./SliderHely.css";
import PlaceContainer from '../place/PlaceContainer'
import leftArrowBack from '../../assets/leftArrowBack.png'



class SliderHely extends Component {

  render() {
    var visibilityHely = "hide";
    if (this.props.sliderVisibilityHely) {
      visibilityHely = "show";
    }

  return (
    <div>
      <div id="flyoutMenuHely" className={visibilityHely}>
        <div id = 'titleHely'>
          <h1>Irodák</h1>
        </div>
        <div id = 'floatingButton'>
          <img id = 'buttonBack' src = {leftArrowBack} onMouseDown={this.props.handleMouseDownHely} ></img>
        </div>
        <div id = 'helyContainer'>
          <PlaceContainer />
        </div>
      </div>
    </div>
    );
  }
}
export default SliderHely;
