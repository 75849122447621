import React, { useEffect } from 'react'

let validateTableList = []
let validateTableHelyList = []
let validateDates = []
let validateDatumList = []
let validateHelyList = []
let validateFaligazdaList = []
let validateFg_id
let validateTableName

export const createTableList = (tableName, tableList, dates, appDatumList, appHelyList, fg_id, appFaligazdaList) => {
  validateTableName = tableName
  let timeTemp = tableList
  validateFg_id = fg_id
  validateDates = dates
  validateDatumList = appDatumList
  validateHelyList = appHelyList
  validateFaligazdaList = appFaligazdaList
  addDates(timeTemp, validateTableName)
  //visibility és cellanév validálása
  addVisibility(timeTemp)
  validateTableList = timeTemp
  return validateTableList

}

export const createHelyList = (tableName, tableList, dates, appDatumList, appHelyList, fg_id, appFaligazdaList) => {
  validateTableName = tableName
  let helyTemp = tableList
  validateFg_id = fg_id
  validateDates = dates
  validateDatumList = appDatumList
  validateHelyList = appHelyList
  validateFaligazdaList = appFaligazdaList
  addDates(helyTemp, validateTableName)
  //visibility és cellanév validálása
  addVisibility(helyTemp)
  validateTableHelyList = helyTemp
  return validateTableHelyList

}

export const elmult = (datum, ora) => {
  let back
  let now = new Date()
  //console.log('validate now. ', now)
  let temp = "2020-" + datum + " " + ora + ":00:00"
  let cellDate = new Date(temp)
  //console.log('validate cellDate ', cellDate)
  cellDate < now ? back = true : back = false
  return back
}

const addDates = (validateTableList, validateTableName) => {
  if(validateTableName == 'table1'){
    validateTableList.forEach((item, index) =>
        item.nap == 'hetfo' ? (item.nap = validateDates[0], item.napSzama = 1) :
        item.nap == 'kedd' ? (item.nap = validateDates[1], item.napSzama = 2) :
        item.nap == 'szerda' ? (item.nap = validateDates[2], item.napSzama = 3) :
        item.nap == 'csutortok' ? (item.nap = validateDates[3], item.napSzama = 4) :
        item.nap == 'pentek' ? (item.nap = validateDates[4], item.napSzama = 5) : null
    )
  }else if(validateTableName == 'table2'){
    validateTableList.forEach((item, index) =>
        item.nap == 'hetfo' ? (item.nap = validateDates[7], item.napSzama = 1) :
        item.nap == 'kedd' ? (item.nap = validateDates[8], item.napSzama = 2) :
        item.nap == 'szerda' ? (item.nap = validateDates[9], item.napSzama = 3) :
        item.nap == 'csutortok' ? (item.nap = validateDates[10], item.napSzama = 4) :
        item.nap == 'pentek' ? (item.nap = validateDates[11], item.napSzama = 5) : null
    )
  }else if(validateTableName == 'table3'){
    validateTableList.forEach((item, index) =>
        item.nap == 'hetfo' ? (item.nap = validateDates[14], item.napSzama = 1) :
        item.nap == 'kedd' ? (item.nap = validateDates[15], item.napSzama = 2) :
        item.nap == 'szerda' ? (item.nap = validateDates[16], item.napSzama = 3) :
        item.nap == 'csutortok' ? (item.nap = validateDates[17], item.napSzama = 4) :
        item.nap == 'pentek' ? (item.nap = validateDates[18], item.napSzama = 5) : null
    )
  }
}

const addVisibility = (validateTableList) => {
  validateTableList.forEach((item, index) =>
    (cellValidate(item.nap, item.ora, item.hely_id, validateDatumList, validateFg_id)) == 'NEMV' ? (item.visibility = 'cellButtonRed', item.buttonName = helyName(item.hely_id).hely_nev, item.fgName = faligazdaName(item.fg_id).fg_nev) :
      (cellValidate(item.nap, item.ora, item.hely_id, validateDatumList, validateFg_id)) == 'FOGLALT' ? (item.visibility ='cellButtonRed', item.buttonName ='FOGLALT' ) :
      (cellValidate(item.nap, item.ora, item.hely_id, validateDatumList, validateFg_id)) == 'OK' ? (item.visibility ='cellButton', item.buttonName = helyName(item.hely_id).hely_nev, item.fgName = faligazdaName(item.fg_id).fg_nev) :
      (cellValidate(item.nap, item.ora, item.hely_id, validateDatumList, validateFg_id)) == 'ELMULT' ? (item.visibility ='cellButtonInvisible', item.buttonName = 'FOGLALT' ) : null
  )
}

const addHelyVisibility = (validateTableList) => {
  validateTableList.forEach((item, index) =>
    (cellHelyValidate(item.nap, item.ora, item.hely_id, validateDatumList, validateFg_id)) == 'NEMV' ? (item.visibility = 'cellHelyButtonRed', item.buttonName = helyName(item.hely_id).hely_nev, item.fgName = faligazdaName(item.fg_id).fg_nev) :
      (cellHelyValidate(item.nap, item.ora, item.hely_id, validateDatumList, validateFg_id)) == 'FOGLALT' ? (item.visibility ='cellHelyButtonRed', item.buttonName ='FOGLALT' ) :
      (cellHelyValidate(item.nap, item.ora, item.hely_id, validateDatumList, validateFg_id)) == 'OK' ? (item.visibility ='cellHelyButton', item.buttonName = helyName(item.hely_id).hely_nev, item.fgName = faligazdaName(item.fg_id).fg_nev) :
      (cellHelyValidate(item.nap, item.ora, item.hely_id, validateDatumList, validateFg_id)) == 'ELMULT' ? (item.visibility ='cellHelyButtonInvisible', item.buttonName = 'FOGLALT' ) : null
  )
}

const helyName = (id) => {
  return validateHelyList.find(element => element.hely_id == id)
}

const faligazdaName = (id) => {
  return validateFaligazdaList.find(element => element.fg_id == id)
}


const cellValidate = (datum, ora, hely_id, datumList, fg_id) => {
  //console.log('validate datumList ', datumList)
  let answer
  if(elmult(datum, ora)){
    //console.log('validate elmult')
    answer = 'ELMULT'
  }else if(hely_id == '10'){
    answer = 'NEMV'
  }else{
    const result = validateDatumList.find(element => element.datum === datum && element.ora === ora && element.fg_id === fg_id);
    result == undefined ? answer = 'OK' : answer = 'FOGLALT'
  }

  return answer
}

const cellHelyValidate = (datum, ora, hely_id, datumList, fg_id) => {
  //console.log('validate datumList ', datumList)
  let answer
  let now = new Date()
  //console.log('validate now. ', now)
  let temp = "2020-" + datum + " " + ora + ":00:00"
  let cellDate = new Date(temp)
  //console.log('validate cellDate ', cellDate)
  if(cellDate < now){
    //console.log('validate elmult')
    answer = 'ELMULT'
  }else if(hely_id == '10'){
    answer = 'NEMV'
  }else{
    const result = validateDatumList.find(element => element.datum === datum && element.ora === ora && element.fg_id === fg_id);
    result == undefined ? answer = 'OK' : answer = 'FOGLALT'
  }
  //console.log('cellHelyValidate nap ', datum, 'cellHelyValidate answer ', answer)
  return answer
}

function Validate() {

  useEffect(() => {
  }, [])


  return(
    <div>

    </div>
  )

}

export default createTableList
