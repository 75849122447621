import React, { useState, useEffect, useContext } from 'react'
import { appContext } from '../../App'
import './TimeContainer.css'
import Dropdown from 'react-bootstrap/Dropdown'
import DateComponent from '../DateComponent'
import {createTableList} from '../Validate'
import Popup from '../popup/Popup'
import DatumOperations from '../datum/DatumOperations'
import choose from '../../assets/choose.png'


let actualList = []
let actualFaligazda = {}
let filteredList = []
let element
let messagePopup
let messageElement
let messageUser
let cellId
let cellName
let tableList1 = []
let tableList2 = []
let tableList3 = []

let cellElement = {datum:''}

function TimeContainer() {

  //appContext
  const [ authenticated, setAuthenticated, appTimeList, setAppTimeList, appFaligazdaList, setAppFaligazdaList, appHelyList, setAppHelyList, appDatumList, setAppDatumList, appContactList, setAppContactList, user, popup, setPopup, popup2, setPopup2, actualFg, setActualFg, actualHely, setActualHely ] = useContext(appContext)

  //saját state
  const [ actualFg_id, setActualFg_id ] = useState([])
  const [ dates, setDates ] = useState([])

  const updateDates = (dates) => {
    setDates(dates);
  }

  const click = (element) => {
    element.buttonName === 'FOGLALT' ? setPopup('show#FOGLALT') :
      element.buttonName === 'nem válaszhtató' ? setPopup('show#NEMV') :
      setPopup( 'show#' + element.fg_id + '#' + element.hely_id + '#' + user.email + '#' + 'lefoglalja az időpontot?\n' + element.buttonName + '\n' + element.fgName + '\n' + element.nap + '  ' + element.ora + ' óra')

  }

  function clickDropdown(fg_id){
    setActualFg_id(fg_id)
    let temp = appFaligazdaList.find(element => element.fg_id == fg_id)
    actualFaligazda.fg_nev = temp.fg_nev
    setActualFg(actualFaligazda)

    //szűrés dropdown fg_id-re
    tableList1 =  appTimeList.filter(function(element) {
       return element.fg_id == fg_id
     });

     //másik két lista előállítása
     tableList2.length = 0
     tableList1.forEach(val => tableList2.push(Object.assign({}, val)));
     tableList3.length = 0
     tableList1.forEach(val => tableList3.push(Object.assign({}, val)));

     //validálás
     tableList1 = createTableList('table1', tableList1, dates, appDatumList, appHelyList, fg_id, appFaligazdaList)
     //console.log('time clickdrop tableList1 ', tableList1)

     tableList2 = createTableList('table2', tableList2, dates, appDatumList, appHelyList, fg_id, appFaligazdaList)
     //console.log('time clickdrop tableList2 ', tableList2)

     tableList3 = createTableList('table3', tableList3, dates, appDatumList, appHelyList, fg_id, appFaligazdaList)
     //console.log('time clickdrop tableList3 ', tableList3)
    }

    return(
      <div>
        <DateComponent  onUpdateDates = {updateDates}/>
        <DatumOperations />

        <div id = 'dropdownDiv'>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">Válassz falugazdászt</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                {element = appFaligazdaList
                  .map((appFaligazdaList, index) =>(<tr key={index}><td><button id = "cellButton" name = {appFaligazdaList.fg_nev} onClick={(e) => clickDropdown(appFaligazdaList.fg_id)}>
                    {appFaligazdaList.fg_nev}</button></td></tr>))}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div id = 'falugazdaszDiv'>
          {actualFaligazda.fg_nev}
        </div>
        <div id = "wrapper">
        <div id = "inside">

          <div id = "oneX">
            <table id = "timeTable">
              <tbody>
                <tr>
                  <th></th><th>{dates[0]}</th><th>{dates[1]}</th><th>{dates[2]}</th><th>{dates[3]}</th><th>{dates[4]}</th>
                </tr>
                <tr>
                <td id = 'cellTime'>08</td>
                  {element = tableList1
                    .filter(fg => fg.ora === '8')
                    .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                      id = {tableElement.visibility} name = {'XXX'}
                      onClick={(e) => click(tableElement)}>
                      {tableElement.buttonName}</button></td>))}
                </tr>
                <tr>
                <td id = 'cellTime'>09</td>
                {element = tableList1
                  .filter(fg => fg.ora === '9')
                  .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                    id = {tableElement.visibility} name = {'XXX'}
                    onClick={(e) => click(tableElement)}>
                    {tableElement.buttonName}</button></td>))}
                </tr>
                <tr>
                <td id = 'cellTime'>10</td>
                {element = tableList1
                  .filter(fg => fg.ora === '10')
                  .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                    id = {tableElement.visibility} name = {'XXX'}
                    onClick={(e) => click(tableElement)}>
                    {tableElement.buttonName}</button></td>))}
                </tr>
                <tr>
                <td id = 'cellTime'>11</td>
                {element = tableList1
                  .filter(fg => fg.ora === '11')
                  .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                    id = {tableElement.visibility} name = {'XXX'}
                    onClick={(e) => click(tableElement)}>
                    {tableElement.buttonName}</button></td>))}
                </tr>
                <tr>
                <td id = 'cellTime'>12</td>
                {element = tableList1
                  .filter(fg => fg.ora === '12')
                  .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                    id = {tableElement.visibility} name = {'XXX'}
                    onClick={(e) => click(tableElement)}>
                    {tableElement.buttonName}</button></td>))}
                </tr>
                <tr>
                <td id = 'cellTime'>13</td>
                {element = tableList1
                  .filter(fg => fg.ora === '13')
                  .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                    id = {tableElement.visibility} name = {'XXX'}
                    onClick={(e) => click(tableElement)}>
                    {tableElement.buttonName}</button></td>))}
                </tr>
                <tr>
                <td id = 'cellTime'>14</td>
                {element = tableList1
                  .filter(fg => fg.ora === '14')
                  .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                    id = {tableElement.visibility} name = {'XXX'}
                    onClick={(e) => click(tableElement)}>
                    {tableElement.buttonName}</button></td>))}
                </tr>
                <tr>
                <td id = 'cellTime'>15</td>
                {element = tableList1
                  .filter(fg => fg.ora === '15')
                  .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                    id = {tableElement.visibility} name = {'XXX'}
                    onClick={(e) => click(tableElement)}>
                    {tableElement.buttonName}</button></td>))}
                </tr>
              </tbody>
            </table>
          </div>

          <div id = "two">
          <table id = "timeTable">
            <tbody>
              <tr>
                <th></th><th>{dates[7]}</th><th>{dates[8]}</th><th>{dates[9]}</th><th>{dates[10]}</th><th>{dates[11]}</th>
              </tr>
              <tr>
              <td id = 'cellTime'>08</td>
                {element = tableList2
                  .filter(fg => fg.ora === '8')
                  .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                    id = {tableElement.visibility} name = {'XXX'}
                    onClick={(e) => click(tableElement)}>
                    {tableElement.buttonName}</button></td>))}
              </tr>
              <tr>
              <td id = 'cellTime'>09</td>
              {element = tableList2
                .filter(fg => fg.ora === '9')
                .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                  id = {tableElement.visibility} name = {'XXX'}
                  onClick={(e) => click(tableElement)}>
                  {tableElement.buttonName}</button></td>))}
              </tr>
              <tr>
              <td id = 'cellTime'>10</td>
              {element = tableList2
                .filter(fg => fg.ora === '10')
                .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                  id = {tableElement.visibility} name = {'XXX'}
                  onClick={(e) => click(tableElement)}>
                  {tableElement.buttonName}</button></td>))}
              </tr>
              <tr>
              <td id = 'cellTime'>11</td>
              {element = tableList2
                .filter(fg => fg.ora === '11')
                .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                  id = {tableElement.visibility} name = {'XXX'}
                  onClick={(e) => click(tableElement)}>
                  {tableElement.buttonName}</button></td>))}
              </tr>
              <tr>
              <td id = 'cellTime'>12</td>
              {element = tableList2
                .filter(fg => fg.ora === '12')
                .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                  id = {tableElement.visibility} name = {'XXX'}
                  onClick={(e) => click(tableElement)}>
                  {tableElement.buttonName}</button></td>))}
              </tr>
              <tr>
              <td id = 'cellTime'>13</td>
              {element = tableList2
                .filter(fg => fg.ora === '13')
                .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                  id = {tableElement.visibility} name = {'XXX'}
                  onClick={(e) => click(tableElement)}>
                  {tableElement.buttonName}</button></td>))}
              </tr>
              <tr>
              <td id = 'cellTime'>14</td>
              {element = tableList2
                .filter(fg => fg.ora === '14')
                .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                  id = {tableElement.visibility} name = {'XXX'}
                  onClick={(e) => click(tableElement)}>
                  {tableElement.buttonName}</button></td>))}
              </tr>
              <tr>
              <td id = 'cellTime'>15</td>
              {element = tableList2
                .filter(fg => fg.ora === '15')
                .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                  id = {tableElement.visibility} name = {'XXX'}
                  onClick={(e) => click(tableElement)}>
                  {tableElement.buttonName}</button></td>))}
              </tr>
            </tbody>
          </table>
          </div>

          <div id = "three">
          <table id = "timeTable">
            <tbody>
              <tr>
                <th></th><th>{dates[14]}</th><th>{dates[15]}</th><th>{dates[16]}</th><th>{dates[17]}</th><th>{dates[18]}</th>
              </tr>
              <tr>
              <td id = 'cellTime'>08</td>
                {element = tableList3
                  .filter(fg => fg.ora === '8')
                  .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                    id = {tableElement.visibility} name = {'XXX'}
                    onClick={(e) => click(tableElement)}>
                    {tableElement.buttonName}</button></td>))}
              </tr>
              <tr>
              <td id = 'cellTime'>09</td>
              {element = tableList3
                .filter(fg => fg.ora === '9')
                .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                  id = {tableElement.visibility} name = {'XXX'}
                  onClick={(e) => click(tableElement)}>
                  {tableElement.buttonName}</button></td>))}
              </tr>
              <tr>
              <td id = 'cellTime'>10</td>
              {element = tableList3
                .filter(fg => fg.ora === '10')
                .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                  id = {tableElement.visibility} name = {'XXX'}
                  onClick={(e) => click(tableElement)}>
                  {tableElement.buttonName}</button></td>))}
              </tr>
              <tr>
              <td id = 'cellTime'>11</td>
              {element = tableList3
                .filter(fg => fg.ora === '11')
                .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                  id = {tableElement.visibility} name = {'XXX'}
                  onClick={(e) => click(tableElement)}>
                  {tableElement.buttonName}</button></td>))}
              </tr>
              <tr>
              <td id = 'cellTime'>12</td>
              {element = tableList3
                .filter(fg => fg.ora === '12')
                .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                  id = {tableElement.visibility} name = {'XXX'}
                  onClick={(e) => click(tableElement)}>
                  {tableElement.buttonName}</button></td>))}
              </tr>
              <tr>
              <td id = 'cellTime'>13</td>
              {element = tableList3
                .filter(fg => fg.ora === '13')
                .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                  id = {tableElement.visibility} name = {'XXX'}
                  onClick={(e) => click(tableElement)}>
                  {tableElement.buttonName}</button></td>))}
              </tr>
              <tr>
              <td id = 'cellTime'>14</td>
              {element = tableList3
                .filter(fg => fg.ora === '14')
                .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                  id = {tableElement.visibility} name = {'XXX'}
                  onClick={(e) => click(tableElement)}>
                  {tableElement.buttonName}</button></td>))}
              </tr>
              <tr>
              <td id = 'cellTime'>15</td>
              {element = tableList3
                .filter(fg => fg.ora === '15')
                .map((tableElement, index) => (<td key={index} id = 'cellTime'><button
                  id = {tableElement.visibility} name = {'XXX'}
                  onClick={(e) => click(tableElement)}>
                  {tableElement.buttonName}</button></td>))}
              </tr>
            </tbody>
          </table>
          </div>
        </div>

        </div>
      </div>
    )
}

export default TimeContainer
