import React, { Component } from "react";
import "./Slider2.css";
import DatumComponent from '../datum/DatumComponent'


class Slider extends Component {

  render() {
    var visibility2 = "hide";
    if (this.props.sliderVisibility2) {
      visibility2 = "show";
    }


  return (
    <div>
      <div id="flyoutMenu2" className={visibility2}>
        <DatumComponent />
      </div>
    </div>
    );
  }
}
export default Slider;
