import React, { Component } from "react";
import './SliderButton.css';
import rightArrowOrange from '../../assets/rightArrowOrange.png'

let buttonClose = 'invisible'

class SliderButton extends Component {



  render() {
    return (
      <img id = 'button' src = {rightArrowOrange} onMouseDown={this.props.handleMouseDown} ></img>
    );
  }

}


export default SliderButton;
