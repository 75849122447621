import React, { useState, useEffect, useContext } from 'react'
import { appContext } from '../../App'
import './DatumComponent.css'
import DateComponent from '../DateComponent'
import Popup from '../popup/Popup'
import DatumOperations from '../datum/DatumOperations'
var dateFormat = require('dateformat')

let actualFaligazda
let element
let actualDatumList
let dummy = {}
dummy.fg_nev = 'NO'
dummy.hely_nev = 'NO'
let theDate = dateFormat(new Date(), "mm-dd")
let foglalas = false



function DatumComponent() {

  //appContext
  const [ authenticated, setAuthenticated, appTimeList, setAppTimeList, appFaligazdaList, setAppFaligazdaList, appHelyList, setAppHelyList, appDatumList, setAppDatumList, appContactList, setAppContactList, user, popup, setPopup, actualFg, setActualFg, reRender, setReRender ] = useContext(appContext)

  //const [ reRe, setReRe ] = useState();
  useEffect(() => {
  //  setReRe(true)
  }, [])

  const createDatumList = () => {
    appDatumList.sort((a, b) => (a.datum > b.datum) ? 1 : (a.datum === b.datum) ? ((a.ora > b.ora) ? 1 : -1) : -1 )

    if(appDatumList.length > 0){
      let tempList = appDatumList.filter(datum => datum.datum > theDate)
      actualDatumList = tempList.filter(datum => datum.contact_id === user.sub)
      actualDatumList.length > 0 ? foglalas = true : foglalas = false
    }else{
      actualDatumList = appDatumList
      foglalas = false
    }

  }

  const helyName = (id) => {
    if(appFaligazdaList.length > 0 && appHelyList.length > 0){
      return appHelyList.find(element => element.hely_id == id)
    }else{
      return dummy
    }

  }

  const faligazdaName = (id) => {
    let theId = id.charAt(0)
    if(appFaligazdaList.length > 0 && appHelyList.length > 0){
      return appFaligazdaList.find(element => element.fg_id == theId)
    }else{
      return dummy
    }
  }

  const datumTable = () =>{

  }

return(
  <div>
    {createDatumList()}
    <div id = 'title'>
      <h1>Időpontfoglalásaim</h1>
    </div>
    {foglalas ? (
      <div id = 'datumTable'>
        <table >
          <tbody >
            <tr>
              <th id = 'tableCell'>dátum</th><th id = 'tableCell'>falugazdász</th><th id = 'tableCell'>helyszín</th>
            </tr>
            {element = actualDatumList
              .map((tableElement, index) => (
              <tr>
                <React.Fragment key={index}>
                  <td id = 'tableCell'>
                    { tableElement.datum }{' '}{ tableElement.ora }{' óra'}
                  </td>
                  <td id = 'tableCell'>
                    { faligazdaName(tableElement.fg_id).fg_nev }
                  </td>
                  <td id = 'tableCell'>
                    { helyName(tableElement.hely_id).hely_nev }
                  </td>
                </React.Fragment>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (<div id = 'foglal'>nincsenek foglalások</div>)}
  </div>
  )}

export default DatumComponent
