import React, { useState, useEffect } from "react";
import { Router } from "react-router-dom";
import Loading from "./components/auth/Loading";
import NavBar from "./components/auth/NavBar";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

import SliderContainer from './components/slider/SliderContainer'
import Nyit from './components/Nyit'
import Fetch from './components/Fetch'
import Popup from './components/popup/Popup'
import Popup2 from './components/popup/Popup2'
import axios from 'axios'
import { insertContact } from './components/contact/ContactOperations'



// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

export const appContext = React.createContext([{}, () => {}])

let popupVisibility

const App = () => {

  const { isLoading, error, user } = useAuth0();
  const [ authenticated = false, setAuthenticated ] = useState([]);
  const [ appTimeList, setAppTimeList ] = useState([]);
  const [ appFaligazdaList, setAppFaligazdaList ] = useState([]);
  const [ appHelyList, setAppHelyList ] = useState([]);
  const [ appDatumList, setAppDatumList ] = useState([]);
  const [ appContactList, setAppContactList ] = useState([]);
  const [ popup, setPopup ] = useState(['hide']);
  const [ popup2, setPopup2 ] = useState('hide');
  const [ actualFg, setActualFg ] = useState(['hide']);
  const [ actualHely, setActualHely ] = useState(['hide']);


  useEffect(() => {
      fetchContact()
  }, [])

  useEffect(() => {
      if(user != false){
        checkContact()
      }
  }, [appContactList])


  function fetchContact() {
    axios.get('https://falugazdaszserver.herokuapp.com/contact/findAll', {
      headers: {Pragma: 'no-cache'}
    })
    .then(response => {
      setAppContactList(response.data)
    })
    .catch(error => {
      console.log(error)
    })
  }

  function checkContact(){
    if(appContactList != undefined){
      if(user != undefined){
        let actualContact
        actualContact = appContactList.find(element => element.email === user.email);
        if(actualContact == undefined){
          insertContact(user)
          setAppContactList(actualContact)
        }else{ console.log('checContact REGISTERED USER')}
      }
    }
  }

  if (error) { return <div>Oops... {error.message}</div>; }

  if (isLoading) { return <Loading />; }

  return (

    <div>
      <Router history={history}>
        <div id = 'keret'>
        <div id="app" className="d-flex flex-column h-100">
          <appContext.Provider value = {[authenticated, setAuthenticated, appTimeList, setAppTimeList, appFaligazdaList, setAppFaligazdaList, appHelyList, setAppHelyList, appDatumList, setAppDatumList, appContactList, setAppContactList, user, popup, setPopup, popup2, setPopup2, actualFg, setActualFg, actualHely, setActualHely ]}>
            <NavBar />
            <div id = "popup" className={popup}>
              <Popup />
            </div>
            <div id = "popup2" className={popup2}>
              <Popup2 />
            </div>
            <Fetch />
            {authenticated ? <SliderContainer theUser = {user}/> : <Nyit theUser = {user}/>}
          </appContext.Provider>
        </div>
        </div>
      </Router>
    </div>
  );
};

export default App;
