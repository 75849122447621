import React, { useEffect } from 'react'

var dateFormat = require('dateformat')
const dates = []
let theDate = new Date()

function DateComponent({ onUpdateDates }) {

  useEffect(() => {
      loadDates()
  }, [])

  Date.prototype.yyyymmdd = function() {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();
    return [this.getFullYear(), '-', (mm>9 ? '' : '0') + mm, '-', (dd>9 ? '' : '0') + dd].join('');
  }

  function loadDates(){
    //console.log('ma ', theDate)
    let monday = startOfWeek(theDate)
    //console.log('hétfő ', monday)
    let i = 0;
    var newdate = monday;
    //console.log('datecomp newdate ', newdate)
    while(i < 21){
      var day=dateFormat(newdate, "mm-dd");
      dates.push(day)
      newdate.setDate(newdate.getDate() + 1);
      i++
    }
    //console.log('hét ', dates)
  }

  function startOfWeek(date){
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }

  return(
    <div>
    {onUpdateDates(dates)}
    </div>
  )

}

export default DateComponent
