import React, { Component } from "react";
import SliderButton from './SliderButton'
import SliderButtonHely from './SliderButtonHely'
import SliderButtonAndroid from './SliderButtonAndroid'
import Slider from './Slider'
import SliderHely from './SliderHely'
import Slider2 from './Slider2'
import Slider3 from './Slider3'
import './SliderContainer.css'
import Nyit from '../Nyit'
import axios from 'axios'

let url
let link

class SliderContainer extends Component {



  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleHely: false,
    };
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseDownHely = this.handleMouseDownHely.bind(this);
    this.handleMouseDownAndroid = this.handleMouseDownAndroid.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  handleMouseDown(e) {
    this.toggleMenu();
    e.stopPropagation();
  }

  handleMouseDownHely(e) {
    this.toggleMenuHely();
    e.stopPropagation();
  }

  handleMouseDownAndroid(e) {
    console.log('SliderButtonAndroid')

    axios.get('https://szempont1.s3.eu-west-3.amazonaws.com/falugazdasz3_0.apk', {
      responseType: 'blob'
    })
    .then(response => {
      url = window.URL.createObjectURL(new Blob([response.data]))
      link = document.createElement('a')
      link.href = link
      link.setAttribute('download', 'falugazdasz.apk')
      document.body.appendChild(link)
      link.click()
    })
    .catch(error => {
      console.log(error)
    })

    e.stopPropagation();
  }

  toggleMenu() {
    this.setState({
      visible: !this.state.visible
    });
  }

  toggleMenuHely() {
    this.setState({
      visibleHely: !this.state.visibleHely
    });
  }

  render() {
    return (
      <div>
        <div id="flyout">
        <SliderButton handleMouseDown={this.handleMouseDown}/>
        <SliderButtonHely handleMouseDownHely={this.handleMouseDownHely}/>
        <SliderButtonAndroid handleMouseDownAndroid={this.handleMouseDownAndroid}/>
        <Slider handleMouseDown={this.handleMouseDown} sliderVisibility={this.state.visible} />
        <SliderHely handleMouseDownHely={this.handleMouseDownHely} sliderVisibilityHely={this.state.visibleHely} />
        <Slider2 sliderVisibility2={this.state.visible || this.state.visibleHely}/>
        <Slider3 sliderVisibility3={this.state.visible || this.state.visibleHely}/>
        <Nyit theUser = {this.props.theUser}/>
        </div>
    </div>
    );
  }
}
  export default SliderContainer;
