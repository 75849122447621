import React, { Component } from "react";
import "./Slider.css";
import TimeContainer from '../time/TimeContainer'
import leftArrowBack from '../../assets/leftArrowBack.png'



class Slider extends Component {

  render() {
    var visibility = "hide";
    if (this.props.sliderVisibility) {
      visibility = "show";
    }

  return (
    <div>
      <div id="flyoutMenu" className={visibility}>
        <div id = 'titleTime'>
          <h1>Falugazdászok</h1>
        </div>
        <div id = 'floatingButton'>
          <img id = 'buttonBack' src = {leftArrowBack} onMouseDown={this.props.handleMouseDown} ></img>
        </div>
        <div id = 'timeContainer'>
          <TimeContainer />
        </div>
      </div>
    </div>
    );
  }
}
export default Slider;
