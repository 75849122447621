import React, { useState, useEffect, useContext } from 'react'
import { appContext } from '../../App'
import './PlaceContainer.css'
import Dropdown from 'react-bootstrap/Dropdown'
import DateComponent from '../DateComponent'
import {createTableList} from '../Validate'
import {createHelyList} from '../Validate'
import {elmult} from '../Validate'
import Popup from '../popup/Popup'
import Popup2 from '../popup/Popup2'
import DatumOperations from '../datum/DatumOperations'
import choose from '../../assets/choose.png'


let actualList = []
let actualFaligazdaList = new Set()
let actualHely = {}
let filteredList = []
let element
let messagePopup
let messageElement
let messageUser
let cellId
let cellName
let helyList1 = []
let helyList2 = []
let helyList3 = []
let helyVisibility
let popupList = []

let cellElement = {datum:''}

export const popUpProp = () =>{
  return popupList
}

function PlaceContainer() {

  //appContext
  const [ authenticated, setAuthenticated, appTimeList, setAppTimeList, appFaligazdaList, setAppFaligazdaList, appHelyList, setAppHelyList, appDatumList, setAppDatumList, appContactList, setAppContactList, user, popup, setPopup, popup2, setPopup2, actualFg, setActualFg, actualHely, setActualHely ] = useContext(appContext)

  //saját state
  const [ actualHely_id, setActualHely_id ] = useState([])
  const [ dates, setDates ] = useState([])

  const updateDates = (dates) => {
    setDates(dates);
  }

  const click = (elmult, caller, element, nap) => {
    popupList.length = 0
    if(elmult){
      setPopup('show#NEMV')
    }else{
      if(caller == 'oneHelyX'){
        popupList = helyList1.filter(listElement => listElement.fg_id == element.fg_id && (listElement.hely_id == actualHely_id || listElement.hely_id == 10) && listElement.nap == nap)
      }else if(caller == 'twoHely'){
        popupList = helyList2.filter(listElement => listElement.fg_id == element.fg_id && (listElement.hely_id == actualHely_id || listElement.hely_id == 10) && listElement.nap == nap)
      }else if(caller == 'threeHely'){
        popupList = helyList3.filter(listElement => listElement.fg_id == element.fg_id && (listElement.hely_id == actualHely_id || listElement.hely_id == 10) && listElement.nap == nap)
      }

      setPopup2('show#' + 'placeTable1#' + element.fg_nev + "#" + nap + "#" + actualHely_id)
    }




  }
  function clickDropdown(hely_id){
    setActualHely_id(hely_id)
    actualFaligazdaList.clear()
    let temp = appHelyList.find(element => element.hely_id == hely_id)
    actualHely.hely_nev = temp.hely_nev
    setActualHely(actualHely)

    //szűrés dropdown hely_id-re

    helyList1 =  appTimeList.filter(function(element) {
       return element.hely_id == hely_id || element.hely_id == 10
     });
     //másik két lista előállítása
     helyList2.length = 0
     helyList1.forEach(val => helyList2.push(Object.assign({}, val)));
     helyList3.length = 0
     helyList1.forEach(val => helyList3.push(Object.assign({}, val)));
     //validálás
     helyList1 = createHelyList('table1', helyList1, dates, appDatumList, appHelyList, hely_id, appFaligazdaList)
     //console.log('place clickdrop validatedHelyList1 ', helyList1)

     helyList2 = createHelyList('table2', helyList2, dates, appDatumList, appHelyList, hely_id, appFaligazdaList)
     //console.log('place clickdrop validatedHelyList2 ', helyList2)

     helyList3 = createHelyList('table3', helyList3, dates, appDatumList, appHelyList, hely_id, appFaligazdaList)
     //console.log('place clickdrop validatedHelyList3 ', helyList3)
    }

    const faligazdaName = (id) => {
      return appFaligazdaList.find(element => element.fg_id == id)
    }


    const makeDayList = (caller, day) => {
      actualFaligazdaList.clear()
      if(caller == "oneHelyX"){
        for(var i = 0; i < helyList1.length; i++){
          if(helyList1[i].nap == day && helyList1[i].hely_id == actualHely_id){
            actualFaligazdaList.add(faligazdaName(helyList1[i].fg_id))
          }
        }
      }else if(caller == "twoHely"){
        for(var i = 0; i < helyList2.length; i++){
          if(helyList2[i].nap == day && helyList2[i].hely_id == actualHely_id){
            actualFaligazdaList.add(faligazdaName(helyList2[i].fg_id))
          }
        }
      }else if(caller == "threeHely"){
        for(var i = 0; i < helyList3.length; i++){
          if(helyList3[i].nap == day && helyList3[i].hely_id == actualHely_id){
            actualFaligazdaList.add(faligazdaName(helyList3[i].fg_id))
          }
        }
      }

      elmult(day, 15) ? helyVisibility = 'cellHelyButtonInvisible' : helyVisibility = 'cellHelyButton'
      actualList = [...actualFaligazdaList]
      //console.log('place actualList ', actualList)

    }

    return(
      <div>
        <DateComponent  onUpdateDates = {updateDates}/>
        <DatumOperations />

        <div id = 'dropdownHelyDiv'>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">Válassz irodát</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                {element = appHelyList
                  .map((appHelyList, index) =>(<tr key={index}><td><button id = "cellButton" name = {appHelyList.hely_nev} onClick={(e) => clickDropdown(appHelyList.hely_id)}>
                    {appHelyList.hely_nev}</button></td></tr>))}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div id = 'falugazdaszHelyDiv'>
          {actualHely.hely_nev}
        </div>
        <div id = "wrapperHely">
        <div id = "insideHely">

          <div id = "oneHelyX">
          <table id = "helyTable">
            <tbody>
              <tr>
                <th>{dates[0]}</th><th>{dates[1]}</th><th>{dates[2]}</th><th>{dates[3]}</th><th>{dates[4]}</th>
              </tr>
              <td id = 'cellWrapper'>
                {makeDayList('oneHelyX', dates[0])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[0], 15), 'oneHelyX', tableElement, dates[0])}>
                    {tableElement.fg_nev}</button></td></tr>))}
              </td>
              <td id = 'cellWrapper'>
                {makeDayList('oneHelyX', dates[1])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[1], 15), 'oneHelyX', tableElement, dates[1])}>
                    {tableElement.fg_nev}</button></td></tr>))}
              </td>
              <td id = 'cellWrapper'>
                {makeDayList('oneHelyX', dates[2])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[2], 15), 'oneHelyX', tableElement, dates[2])}>
                    {tableElement.fg_nev}</button></td></tr>))}
              </td>
              <td id = 'cellWrapper'>
                {makeDayList('oneHelyX', dates[3])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[3], 15), 'oneHelyX', tableElement, dates[3])}>
                    {tableElement.fg_nev}</button></td></tr>))}
              </td>
              <td id = 'cellWrapper'>
                {makeDayList('oneHelyX', dates[4])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[4], 15), 'oneHelyX', tableElement), dates[4]}>
                    {tableElement.fg_nev}</button></td></tr>))}
              </td>


            </tbody>
          </table>
          </div>

          <div id = "twoHely">
          <table id = "helyTable">
            <tbody>
              <tr>
                <th>{dates[7]}</th><th>{dates[8]}</th><th>{dates[9]}</th><th>{dates[10]}</th><th>{dates[11]}</th>
              </tr>
              <td id = 'cellWrapper'>
                {makeDayList('twoHely', dates[7])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[7], 15),'twoHely', tableElement, dates[7])}>
                    {tableElement.fg_nev}</button></td></tr>
                    ))}
              </td>
              <td id = 'cellWrapper'>
                {makeDayList('twoHely', dates[8])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[8], 15), 'twoHely', tableElement, dates[8])}>
                    {tableElement.fg_nev}</button></td></tr>))}
              </td>
              <td id = 'cellWrapper'>
                {makeDayList('twoHely', dates[9])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[9], 15), 'twoHely', tableElement, dates[9])}>
                    {tableElement.fg_nev}</button></td></tr>))}
              </td>
              <td id = 'cellWrapper'>
                {makeDayList('twoHely', dates[10])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[10], 15), 'twoHely', tableElement, dates[10])}>
                    {tableElement.fg_nev}</button></td></tr>))}
              </td>
              <td id = 'cellWrapper'>
                {makeDayList('twoHely', dates[11])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[11], 15), 'twoHely', tableElement, dates[11])}>
                    {tableElement.fg_nev}</button></td></tr>))}
              </td>


            </tbody>
          </table>
          </div>

          <div id = "threeHely">
          <table id = "helyTable">
            <tbody>
              <tr>
                <th>{dates[14]}</th><th>{dates[15]}</th><th>{dates[16]}</th><th>{dates[17]}</th><th>{dates[18]}</th>
              </tr>
              <td id = 'cellWrapper'>
                {makeDayList('threeHely', dates[14])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[14], 15), 'threeHely', tableElement, dates[14])}>
                    {tableElement.fg_nev}</button></td></tr>))}
              </td>
              <td id = 'cellWrapper'>
                {makeDayList('threeHely', dates[15])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[15], 15), 'threeHely', tableElement, dates[15])}>
                    {tableElement.fg_nev}</button></td></tr>))}
              </td>
              <td id = 'cellWrapper'>
                {makeDayList('threeHely', dates[16])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[16], 15), 'threeHely', tableElement, dates[16])}>
                    {tableElement.fg_nev}</button></td></tr>))}
              </td>
              <td id = 'cellWrapper'>
                {makeDayList('threeHely', dates[17])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[17], 15), 'threeHely', tableElement, dates[17])}>
                    {tableElement.fg_nev}</button></td></tr>))}
              </td>
              <td id = 'cellWrapper'>
                {makeDayList('threeHely', dates[18])}
                {element = actualList
                  .map((tableElement, index) => (<tr key={index}><td id = 'cellHely'><button
                    id = {helyVisibility}
                    onClick={(e) => click(elmult(dates[18], 15), 'threeHely', tableElement, dates[18])}>
                    {tableElement.fg_nev}</button></td></tr>))}
              </td>


            </tbody>
          </table>
          </div>
        </div>

        </div>
      </div>
    )
}

export default PlaceContainer
