import React, { useContext } from 'react'
import './Popup.css'
import { appContext } from '../../App'
import DatumOperations from '../datum/DatumOperations'
import buttonYes from '../../assets/buttonYes.png'
import buttonNo from '../../assets/buttonNo.png'

let popupMessage
let buttonClose = 'invisible'
let buttonOk = 'invisible'
let originalMessage

function Popup() {


//appContext
const [ authenticated, setAuthenticated, appTimeList, setAppTimeList, appFaligazdaList, setAppFaligazdaList, appHelyList, setAppHelyList, appDatumList, setAppDatumList, appContactList, setAppContactList, user, popup, setPopup, actualFg, setActualFg, actualHely, setActualHely ] = useContext(appContext)


  function setPop() {
    //console.log('popup setpop popup ', popup)

    let splittedMessage

    if(popup != 'hide'){
      splittedMessage = popup.split("#", 5)
    }else{
      splittedMessage = ''
    }

    switch(popup){
      case 'show#NEMV' : {

        popupMessage = 'Az időpont nem választható'
        buttonClose = 'visible'
        buttonOk = 'invisible'
        break
      }
      case 'show#FOGLALT' : {
        popupMessage = 'Az időpont nem választható'
        buttonClose = 'visible'
        buttonOk = 'invisible'

        break
      }
      case 'close' : {
        setPopup('hide')
        break
      }
      case 'OK' : {
          DatumOperations(originalMessage)
          setPopup('hide')
        break
      }
      case 'show#MESSAGE' : {
        popupMessage = 'Az időponfoglalást elküldtük'
        buttonClose = 'visible'
        buttonOk = 'invisible'
        break
      }
      default : {
        originalMessage = popup
        popupMessage = splittedMessage[4]
        buttonClose = 'visible'
        buttonOk = 'visible'
      }
    }
  }

    return(
      <div>
        {popup === 'show#FOGLALT' ? setPop('show#FOGLALT') :
          popup === 'show#NEMV' ? setPop('show#NEMV') :
          popup === 'MESSAGE' ? setPop('MESSAGE') :
          setPop('OK')}
        <div>
          {(popupMessage == undefined && popup == 'hide') ? <div>undefined</div> : <textarea id = 'texta' >{popupMessage}</textarea>}
        </div>

        {<br></br>}{<br></br>}
        <div id = 'button1' className = {buttonClose}>
        <img id = 'buttonNo' src = {buttonNo} onClick = {(e) => setPopup("close")} ></img>
        </div>
        <div id = 'button2' className = {buttonOk}>
        <img id = 'buttonYes' src = {buttonYes} onClick = {(e) => setPopup("OK")} ></img>
        </div>
      </div>
    )

}

export default Popup
