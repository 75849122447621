import React, { Component } from "react";
import './SliderButtonHely.css';
import rightArrowIroda from '../../assets/rightArrowIroda.png'

let buttonClose = 'invisible'

class SliderButtonHely extends Component {



  render() {
    return (
      <img id = 'buttonHely' src = {rightArrowIroda} onMouseDown={this.props.handleMouseDownHely} ></img>
    );
  }

}


export default SliderButtonHely;
