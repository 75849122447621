import React, { Component } from "react";
import './SliderButtonAndroid.css';
import rightArrowAndroid from '../../assets/rightArrowAndroid.png'

let buttonClose = 'invisible'

class SliderButtonAndroid extends Component {



  render() {
    return (
      <img id = 'buttonAndroid' src = {rightArrowAndroid} onMouseDown={this.props.handleMouseDownAndroid} ></img>
    );
  }

}

export default SliderButtonAndroid;
