import React, { useState, useContext, useEffect } from 'react'
import { appContext } from '../../App'
import axios from 'axios'

let userToSave = {}
let userSave

export function insertContact(contact) {
  console.log('contactop contact ', contact)
      axios.post('contact/insert', {
        email : contact.email,
        password : null,
        activate : contact.sub,
        picture : contact.picture,
        enabled : contact.email_verified,

    })
    .then(response => {
      console.log('contactop NEW USER')
      }
    )
    .catch( error => {
        console.log(error);
    });
}

function ContactOperations() {

  //appContext
  const [ authenticated, setAuthenticated, appTimeList, setAppTimeList, appFaligazdaList, setAppFaligazdaList, appHelyList, setAppHelyList, appDatumList, setAppDatumList, appContactList, setAppContactList, user, popup, setPopup ] = useContext(appContext)

/*
  const deleteContact = (e) => {
    console.log('deletecontact ', contact)
    e.preventDefault()
    axios.delete(`contact/delete/${contact.id} `)
      .then(response => {

        let updateList = contactList.filter(item => item.id !== contact.id)
        console.log('form newState ', updateList, ' contactid ', contact.id)
        setContactList(prevState => (updateList))
        }
      )
      .catch( error => {
          console.log(error);
      });
  }

  const findContact = (e) => {
    e.preventDefault()
    setContact(contactList.find(obj => obj.id === contact.id))
    console.log('find ', contact.email)
    toggleHidden = ''
  }

  const updateContact = (e) => {
    e.preventDefault()
    axios.patch(`contact/update/${contact.id} `, {
        email : contact.email,
        password : contact.password,
        activate : contact.activate,
        picture : contact.picture,
        enabled : contact.enabled
      })
      .then(response => {
        update('email', contact.email)
        console.log('update contactList ', contactList)
        setContactList([...contactList, contact])
        }
      )
      .catch( error => {
          console.log(error);
      });
  }

  function update(propName, value) {
     let item = contactList.find((v) => {
        return v.id == contact.id;
     });
     if (item && item.hasOwnProperty(propName)) {
        item[propName] = value;
     }
  };

*/
  return (
    <div>

    </div>
    )
}

export default ContactOperations
