import React, { useState, useContext } from 'react'
import { appContext } from '../../App'
import axios from 'axios'
import $ from "jquery";


let maxId = 0
let toggleHidden = 'hidden'
let splittedMessage
let splitAgain
let splitNapOra


function DatumOperations(messagePopup) {
  //console.log('datumop messagePopup ', messagePopup)

  //appContext
  const [ authenticated, setAuthenticated, appTimeList, setAppTimeList, appFaligazdaList, setAppFaligazdaList, appHelyList, setAppHelyList, appDatumList, setAppDatumList, appContactList, setAppContactList, user, popup, setPopup ] = useContext(appContext)


  if(messagePopup.length > 0){
    splittedMessage = messagePopup.split("#", 5)
    //insertDatum(splittedMessage)
  }else{
    splittedMessage = ''
  }

  if(splittedMessage.length > 0){
    splitAgain = splittedMessage[4].split("\n", 5)
    //insertDatum(splittedMessage)
  }else{
    splitAgain = ''
  }

  if(splitAgain.length > 0){
    splitNapOra = splitAgain[3].split(" ", 3)
    insertDatum(splittedMessage)
  }else{
    splitNapOra = ''
  }


  function insertDatum(splittedMessage) {
        axios.post('https://falugazdasznode.herokuapp.com/datum/insert', {
        headers: {'content-type': undefined},
        datum : splitNapOra[0],
        ora : splitNapOra[2],
        fg_id : splittedMessage[1],
        hely_id : splittedMessage[2],
        contact_id : user.sub


      })

      .then(response => {
        setPopup('show#MESSAGE')
        }
      )
      .catch( error => {
          console.log(error);
      });

  }

/*
    function insertDatum(splittedMessage) {
      $.ajax({
      method: 'POST',
      url: 'https://falugazdasznode.herokuapp.com/datum/insert',
      data: {
        headers: {'content-type': undefined},
        datum : splitNapOra[0],
        ora : splitNapOra[2],
        fg_id : splittedMessage[1],
        hely_id : splittedMessage[2],
        contact_id : user.sub
      },
      dataType: "json"
    })
      .done(function( msg ) {
        alert( "Data Saved: " + msg );
      });

    }

*/

/*
  const deleteContact = (e) => {
    console.log('deletecontact ', contact)
    e.preventDefault()
    axios.delete(`contact/delete/${contact.id} `)
      .then(response => {

        let updateList = contactList.filter(item => item.id !== contact.id)
        console.log('form newState ', updateList, ' contactid ', contact.id)
        setContactList(prevState => (updateList))
        }
      )
      .catch( error => {
          console.log(error);
      });
  }

  const findContact = (e) => {
    e.preventDefault()
    setContact(contactList.find(obj => obj.id === contact.id))
    console.log('find ', contact.email)
    toggleHidden = ''
  }

  const updateContact = (e) => {
    e.preventDefault()
    axios.patch(`contact/update/${contact.id} `, {
        email : contact.email,
        password : contact.password,
        activate : contact.activate,
        picture : contact.picture,
        enabled : contact.enabled
      })
      .then(response => {
        update('email', contact.email)
        console.log('update contactList ', contactList)
        setContactList([...contactList, contact])
        }
      )
      .catch( error => {
          console.log(error);
      });
  }

  function update(propName, value) {
     let item = contactList.find((v) => {
        return v.id == contact.id;
     });
     if (item && item.hasOwnProperty(propName)) {
        item[propName] = value;
     }
  };

*/
  return (
    <div>

    </div>
    )
}

export default DatumOperations
