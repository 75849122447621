import React, { useEffect, useContext } from 'react'
import axios from 'axios'
import { appContext } from '../App'
import ContactOperations from './contact/ContactOperations'
import { useAuth0 } from "@auth0/auth0-react";


let temp
let changed
let xxx

function Fetch() {

  //appContext
  const [ authenticated, setAuthenticated, appTimeList, setAppTimeList, appFaligazdaList, setAppFaligazdaList, appHelyList, setAppHelyList, appDatumList, setAppDatumList, appContactList, setAppContactList, user, popup, setPopup ] = useContext(appContext)

  useEffect(() => {
      fetchTimes()
      fetchFaligazda()
      fetchHely()
      fetchDatum()
  }, [])


  function fetchTimes() {
    axios.get('https://falugazdaszserver.herokuapp.com/ido/findAll', {
      headers: {Pragma: 'no-cache'}
    })
    .then(response => {

      response.data.forEach((item, index) =>
        (temp = item.ora,
          changed = temp.replace('"', ''),
          item.ora = changed
        )
      )
      setAppTimeList(response.data)
    })
    .catch(error => {
      console.log(error)
    })
  }


  function fetchFaligazda() {
    axios.get('https://falugazdaszserver.herokuapp.com/faligazda/findAll', {
      headers: {Pragma: 'no-cache'}
    })
    .then(response => {
      setAppFaligazdaList(response.data)
    })
    .catch(error => {
      console.log(error)
    })
  }

  function fetchHely() {
    axios.get('https://falugazdaszserver.herokuapp.com/hely/findAll', {
      headers: {Pragma: 'no-cache'}
    })
    .then(response => {
      response.data[0].hely_id = 11
      response.data[1].hely_id = 21
      response.data[2].hely_id = 31
      response.data[3].hely_id = 41
      response.data[4].hely_id = 51
      response.data[5].hely_id = 61
      response.data[6].hely_id = 71
      response.data[7].hely_id = 81
      response.data[8].hely_id = 91
      setAppHelyList(response.data)
    })
    .catch(error => {
      console.log(error)
    })
  }

  function fetchDatum() {
    axios.get('https://falugazdaszserver.herokuapp.com/datum/findAll', {
      headers: {Pragma: 'no-cache'}
    })
    .then(response => {
      setAppDatumList(response.data)
    })
    .catch(error => {
      console.log(error)
    })
  }

return(
  <div id = "wrapper">
    <ContactOperations />
  </div>
)

}

export default Fetch
