import React, { useState, useEffect, useContext } from "react";
import { appContext } from '../App'
import './Nyit.css'
import BackgroundSlider from 'react-background-slider'
import alap_1 from '../assets/alap_1.jpg'
import alap_2 from '../assets/alap_2.jpg'
import alap_3 from '../assets/alap_3.jpg'
import alap_4 from '../assets/alap_4.jpg'
import alap_5 from '../assets/alap_5.jpg'
import alap_6 from '../assets/alap_6.jpg'
import alap_7 from '../assets/alap_7.jpg'
import alap_8 from '../assets/alap_8.jpg'
import alap_9 from '../assets/alap_9.jpg'
import alap_10 from '../assets/alap_10.jpg'
import AtTheMoment from './time/AtTheMoment'

let pictures = []

function Nyit(theUser) {

  //appContext
  const [ authenticated, setAuthenticated, appTimeList, setAppTimeList, appFaligazdaList, setAppFaligazdaList, appHelyList, setAppHelyList, appDatumList, setAppDatumList, appContactList, setAppContactList, user, popup, setPopup, actualFg, setActualFg ] = useContext(appContext)


  useEffect(() => {
      fillArray()
  }, [user])

  const fillArray = () => {
    console.log('NYIT FILLARRAY')
    pictures.length = 0
    if(user != undefined){
      if(user == false){
        pictures.push(alap_1)
        pictures.push(alap_2)
        pictures.push(alap_3)
        pictures.push(alap_4)
        pictures.push(alap_5)
      }else{
        pictures.push(alap_6)
        pictures.push(alap_7)
        pictures.push(alap_8)
        pictures.push(alap_9)
        pictures.push(alap_10)
      }
    }

  }

  return(
    <div>
      {console.log('NYIT PICTURES ', pictures, ' alap_1 ', alap_1)}
    {!authenticated ? <AtTheMoment /> : null}
      <BackgroundSlider
        id = 'base'
        images={[pictures]}
        duration={10} transition={2} />
    </div>
)
}


export default Nyit
