import React, { useState, useEffect, useContext } from "react"
import { appContext } from '../../App'
import './atTheMoment.css'

let theDate = new Date()
let todayName
let varjuk
let countedFG = 0
let countedHely = 0
let styl
let rowOne
let rowTwo
let rowThree
let rowFour
let rowFive
let rowSix
let nextFree
let nextFreeObj = {}


function AtTheMoment() {



  //appContext
  const [ authenticated, setAuthenticated, appTimeList, setAppTimeList, appFaligazdaList, setAppFaligazdaList, appHelyList, setAppHelyList, appDatumList, setAppDatumList, appContactList, setAppContactList, user, popup, setPopup, actualFg, setActualFg, reRender, setReRender ] = useContext(appContext)

  const createMomentList = () => {
    if(user != undefined){
      if(user == false){
        styl = 'wrapp'
      }else{
        styl = 'other'
      }
    }
    dayName(theDate.getDay())

    let tempList = appTimeList.filter(element => element.nap == todayName && element.ora > theDate.getHours() && element.allapot == 'sz')
    if(tempList.length != 0){
      countFG(tempList)
      countHely(tempList)
      setNextFree(appTimeList, dayName(theDate.getDay()))
      rowOne = 'Jelen pillanatban'
      rowTwo = countedFG
      rowThree = 'falugazdász kolléga'
      rowFour = countedHely
      rowFive = 'helyszínen'
      rowSix = 'áll rendelkezésére...'
    }else{
      setNextFree(appTimeList, nextDay(todayName))
      rowOne = 'Legközelebb'
      if(nextFreeObj != undefined){
        rowTwo = varjukName(nextFreeObj.nap)
        rowFour = nextFreeObj.ora
      }

      rowFive = 'órától'
      rowSix = 'várjuk...'
    }

  }

  const dayName = (day) => {
    switch(day){
      case 1 : {  todayName = 'hetfo'
                  break }
      case 2 : {  todayName = 'kedd'
                  break }
      case 3 : {  todayName = 'szerda'
                  break }
      case 4 : {  todayName = 'csutortok'
                  break }
      case 5 : {  todayName = 'pentek'
                  break }
      default : {  todayName = 'hetvege'
                  break }
    }
    return todayName
  }

  const nextDay = (day) => {
    switch(day){
      case 'hetfo' : {  nextFree = 'kedd'
                        break }
      case 'kedd' : {  nextFree = 'szerda'
                        break }
      case 'szerda' : {  nextFree = 'csutortok'
                        break }
      case 'csutortok' : {  nextFree = 'pentek'
                        break }
      case 'pentek' : {  nextFree = 'hetfo'
                        break }
      case 'hetvege' : {  nextFree = 'hetfo'
                                          break }
      default : {  nextFree = 'hetfo'
                        break }
    }
    return nextFree
  }

  const varjukName = (day) => {
    switch(day){
      case 'hetfo' : {  varjuk = 'hétfő'
                        break }
      case 'kedd' : {  varjuk = 'kedd'
                        break }
      case 'szerda' : {  varjuk = 'szerda'
                        break }
      case 'csutortok' : {  varjuk = 'csütörtök'
                        break }
      case 'pentek' : {  varjuk = 'péntek'
                        break }
      case 'hetvege' : {  varjuk = 'hétfő'
                                          break }
      default : {  varjuk = 'hétfő'
                        break }
    }
    return varjuk
  }

  const countFG = (dataset) => {
    countedFG = 0
    let fgId = dataset[0].fg_id
    countedFG++
    for(var i = 0; i < dataset.length; i++){
      if(dataset[i].fg_id != fgId){
        fgId = dataset[i].fg_id
        countedFG++
      }
    }
  }

  const countHely = (dataset) => {
    countedHely = 0
    let helyId = dataset[0].hely_id
    countedHely++
    for(var i = 0; i < dataset.length; i++){
      if(dataset[i].hely_id != helyId){
        helyId = dataset[i].hely_id
        countedHely++
      }
    }
  }

  const setNextFree = (dataset, day) => {

    let tempList = dataset.filter(element => element.nap == nextFree && element.allapot == 'sz')
    nextFreeObj = tempList[0]
  }


  return(
    <div>
      {createMomentList()}
      <div id = {styl}>
        <div id = 'jelen'>
          {rowOne}
        </div>
        <div id = 'countedFaligazda'>
          {rowTwo}
        </div>
        <div id = 'faligazda'>
          {rowThree}
        </div>
        <div id = 'countHely'>
          {rowFour}
        </div>
        <div id = 'hely'>
          {rowFive}
        </div>
        <div id = 'rend'>
          {rowSix}
        </div>
      </div>
    </div>
)
}


export default AtTheMoment
