import React, { Component } from "react";
import "./Slider3.css";
import AtTheMoment from '../time/AtTheMoment'


class Slider3 extends Component {

  render() {
    var visibility3 = "hide";
    if (this.props.sliderVisibility3) {
      visibility3 = "show";
    }


  return (
    <div>
      <div id="flyoutMenu3" className={visibility3}>
        <AtTheMoment />
      </div>
    </div>
    );
  }
}
export default Slider3;
